import React from 'react'

import {
  LazyLoadComponent,
  //   LazyLoadImage,
} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

//swiper
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

//images
import RequestBgImg1 from '../../images/new-homepage/about-us/ob-gyn.jpg'
import RequestBgImg2 from '../../images/new-homepage/about-us/request-img-placeholder2.jpg'
import RequestBgImg3 from '../../images/new-homepage/about-us/surgeon.jpg'
import RequestBgImg4 from '../../images/new-homepage/about-us/request-img-placeholder4.jpg'

// icons
// import LeftArrowIcon from '../../images/new-homepage/left-arrow.svg'
// import RightArrowIcon from '../../images/new-homepage/right-arrow.svg'

const RequestSwiper = () => {
  const RequestsSwiperData = [
    {
      quote: `"I wasn't convinced coaching was for me, but Sandy opened my mind to different skill sets and
        creative problem solving techniques that I now use daily."`,
      role: 'OB/GYN (Denver, Colorado)',
      img: RequestBgImg1,
    },
    {
      quote: `"During my coaching… I set clearer expectations and guided my team to come up with a solution
      which they implemented. Staffing, morale, and patient care have all improved. I learned I don’t
      need to fix things by myself."`,
      role: 'Medical Director (New England)',
      img: RequestBgImg2,
    },
    {
      quote: `"I wanted to leave medicine but now I look forward to work"`,
      role: 'Surgeon (Phoenix, Arizona)',
      img: RequestBgImg3,
    },
    {
      quote: `"Sandy gets the physician perspective and helps us all realize our potential as leaders."`,
      role: 'Chief Medical Officer (Scottsdale, Arizona)',
      img: RequestBgImg4,
    },
  ]

  const articleCarousel = {
    loop: true,
    // spaceBetween: 10,
    speed: 2000,
    autoplay: {
      delay: 10000,
    },
    // autoplay: false,
    disableOnInteraction: false,
    navigation: {
      nextEl: '#article-next',
      prevEl: '#article-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
      clickable: true,
    },
    fadeEffect: {
      crossFade: true,
    },
    breakpoints: {
      1366: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      767: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  }

  return (
    <>
      <div className="request-swiper" style={{ width: '100%', height: '100%' }}>
        {/* <div className="container h-100 w-100"> */}
        {/* <div className="row h-100 w-100"> */}
        {/* <div class="fullwidth-nav d-none">
          <div class="fullwidth-nav-prev" id="article-prev">
            <img src={LeftArrowIcon} alt="Left Arrow" />
          </div>
          <div class="fullwidth-nav-next" id="article-next">
            <img src={RightArrowIcon} alt="Right Arrow" />
          </div>
        </div> */}
        <LazyLoadComponent>
          <Swiper {...articleCarousel}>
            {RequestsSwiperData.map(request => (
              <div className="swiper-slide" style={{ position: 'relative' }}>
                <img
                  src={request.img}
                  alt={request.role}
                  style={{
                    height: '100vh',
                    objectFit: 'cover',
                    width: '100%',
                    zIndex: -1,
                    filter: 'brightness(0.8)',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10%',
                    left: 0,
                    // bottom: 0,
                    zIndex: 1,
                    padding: '0 5rem',
                    width: '75%',
                  }}
                >
                  <p className="text-white review-quote">{request.quote}</p>
                  <p className="text-white review-role mt-4">{request.role}</p>
                </div>
              </div>
            ))}
          </Swiper>
        </LazyLoadComponent>
        {/* </div> */}
        {/* </div> */}
      </div>
    </>
  )
}

export default RequestSwiper
