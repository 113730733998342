import WebriQForm from '@webriq/gatsby-webriq-form'
import React from 'react'

const RequestForm = () => {
  return (
    <WebriQForm
      data-form-id="be600605-a81d-4acb-b15b-d2dcaba0cdda"
      id="request-free-coaching-session"
      name="Request Free Coaching Session"
      className="request-free-coaching-session mb-0"
      method="POST"
    >
      <div className="container px-0">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <input
                className="w-100"
                type="text"
                name="First Name"
                id="first_name"
                placeholder="First Name"
                required
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <input
                className="w-100"
                type="text"
                name="Last Name"
                id="last_name"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group mt-4">
              <input
                className="w-100"
                type="email"
                name="Email Address"
                id="email_address"
                placeholder="Email"
                required
              />
            </div>
            <div className="form-group mt-4">
              <select
                id="role"
                name="role"
                className="w-100"
                required
                style={{
                  background: '#fff',
                  height: '3rem',
                  borderRadius: '5px',
                }}
              >
                <option selected disabled="disabled">
                  Please Select
                </option>
                <option value="Physician">Physician</option>
                <option value="Staff">Staff</option>
                <option value="Administrator">Administrator</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="form-group mt-4">
              <input
                className="w-100"
                type="text"
                name="Organization"
                id="organization_name"
                placeholder="Organization"
                required
              />
              <div className="form-group mt-4">
                <textarea
                  type="text"
                  name="Message Us"
                  id="send_message"
                  rows="4"
                  className="w-100"
                  placeholder="What would you like us to know?"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <div className="webriq-recaptcha" />
            </div>

            <div className="form-group mt-4">
              <button
                className="btn-default btn-purple w-100"
                type="submit"
                style={{ border: 'none' }}
              >
                Request a Call
              </button>
            </div>
          </div>
        </div>
      </div>
    </WebriQForm>
  )
}

export default RequestForm
