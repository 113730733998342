import React from 'react'
// import Header from '../components/header/header'
import SEO from '../components/seo'
import { Link } from 'gatsby'

//images
import logo from '../images/logo.png'
import ama from '../images/ama.png'
import LogoRow from '../components/LogoRowRequest'

//form
import RequestForm from '../components/forms/request-form'

//swiper
import RequestSwiper from '../components/swiper/request-swiper'
import WebriQForm from '@webriq/gatsby-webriq-form'

const RequestFreeCoaching = () => {
  return (
    <>
      {/* <Header /> */}
      <SEO
        title="Step into your genius zone"
        description="Your 60-minute Free Coaching Session is the next step to realizing your dream for your work -- and
your life."
      />
      <div className="rfcs-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-4 col-md-10 mx-auto"
              style={{ marginTop: '7.5rem' }}
            >
              <Link to="/">
                <img
                  // width={scrollY > 0 ? "100%" : "100%"}
                  id="logo-size"
                  src={logo}
                  alt="Sandy Scott"
                  className="logo sepia img-fluid"
                  style={{ width: '40%' }}
                />
              </Link>
              <div
                className="mt-5"
                // style={{
                //   height: '90vh',
                //   display: 'flex',
                //   flexDirection: 'column',
                //   alignItems: 'center',
                //   justifyContent: 'center',
                //   padding: '8rem 0',
                // }}
              >
                <h1 className="mb-0">Step into your genius zone.</h1>
                <h4 className="my-4">
                Physicians like you stand at the core of healthcare. As you elevate admin roles, the horizon of your impact broadens, and the journey gets tougher. 
                </h4>
                <p>
                Overwhelmed by red tape? Chasing perfection? Seeking deeper meaning? Our coaching transcends quick fixes, focusing on nurturing your intrinsic leadership abilities. Let's explore if we're the right fit – if not, we promise to guide you to the perfect resource.
                </p>
                <div className="text-left w-100 mt-4">
                  {/* <Link
                    to="/request/#request-form-wrapper"
                    className="btn-default btn-purple"
                  >
                    Get Started
                  </Link> */}
                </div>
               
              </div>
              <div
                id="request-form-wrapper"
                style={{
                  height: '90vh',
                  marginTop: '3rem',
                }}
              >
                <WebriQForm
                  data-form-id="be600605-a81d-4acb-b15b-d2dcaba0cdda"
                  id="request-free-coaching-session"
                  name="Request Free Coaching Session"
                  className="request-free-coaching-session mb-0"
                  method="POST"
                >
                  <div className="container px-0">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            className="w-100"
                            type="text"
                            name="First Name"
                            id="first_name"
                            placeholder="First Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            className="w-100"
                            type="text"
                            name="Last Name"
                            id="last_name"
                            placeholder="Last Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mt-4">
                          <input
                            className="w-100"
                            type="email"
                            name="Email Address"
                            id="email_address"
                            placeholder="Email"
                            required
                          />
                        </div>
                        {/* <div className="form-group mt-4">
                          <select
                            id="role"
                            name="role"
                            className="w-100"
                            required
                            style={{
                              background: '#fff',
                              height: '3rem',
                              borderRadius: '5px',
                            }}
                          >
                            <option selected disabled="disabled">
                              Please Select
                            </option>
                            <option value="Physician">Physician</option>
                            <option value="Staff">Staff</option>
                            <option value="Administrator">Administrator</option>
                            <option value="Other">Other</option>
                          </select>
                        </div> */}
                        {/* <div className="form-group mt-4">
                          <input
                            className="w-100"
                            type="text"
                            name="Organization"
                            id="organization_name"
                            placeholder="Organization"
                            required
                          /> 
                          </div>*/}
                          <div className="form-group mt-4">
                            <textarea
                              type="text"
                              name="Message Us"
                              id="send_message"
                              rows="4"
                              className="w-100"
                              placeholder="What would you like us to know?"
                              required
                            />
                          </div>
                        
                        <div className="form-group">
                          <div className="webriq-recaptcha" />
                        </div>

                        <div className="form-group mt-4">
                          <button
                            className="btn-default btn-purple w-100"
                            type="submit"
                            style={{ border: 'none' }}
                          >
                           Let's Connect
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </WebriQForm>
                {/* <RequestForm /> */}
                <LogoRow />
                <div className="w-50">
                  <img src={ama} alt="AMA" className="img-fluid my-4" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0">
              <div style={{ position: 'fixed' }}>
                {/* <div> */}
                <RequestSwiper />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestFreeCoaching
